<template>
  <div>
    <custom-toolbar
      :total="$store.getters['recovery/total']"
      :title="$t('Recoveries')"
      @refresh="getRecoveries"
      />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.recovery.field.amount"
              label="Filtrer par montant"
              outlined
              dense
              placeholder="Filtrer par montant"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.recovery.field.user"
              label="Filtrer par utilisateur"
              outlined
              dense
              placeholder="Filtrer par utilisateur"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.recovery.field.admin"
              label="Filtrer par validateur"
              outlined
              dense
              placeholder="Filtrer par validateur"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        :headers="headers"
        :items="$store.getters['recovery/recoveries']"
        :items-per-page="$store.getters['recovery/per_page']"
        :page.sync="$store.getters['recovery/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.admin`]="{item}">
          {{ item.admin !== null ? (item.admin.name !== null ? item.admin.name : item.admin.phone_number) : null }}
        </template>
        <template #[`item.amount`]="{item}">
          <v-chip
            color="primary"
            small
          >
            {{ formatAmount(item.amount) }}
          </v-chip>
        </template>
        <template #[`item.fee_amount`]="{item}">
          <v-chip
            :color="item.fee_amount ? 'info' : null"
            small
          >
            {{ formatAmount(item.fee_amount) }}
          </v-chip>
        </template>
        <template #[`item.to_withdraw`]="{item}">
          <v-chip
            small
            :color="item.to_withdraw ? 'primary' : 'error'"
            :class="`${item.to_withdraw ? 'primary' : 'error'}--text`"
            class="v-chip-light-bg"
          >
            {{ item.to_withdraw === true ? 'OUI' : 'NON' }}
          </v-chip>
        </template>
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="item.status.color"
          >
            {{ item.status.label }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            @view="openItem(item)"
            :lock="false"
            :edit="false"
            :remove="false"
            :status="false"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.recovery.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getRecoveriesPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getRecoveries"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Voulez-vous bloquer cet utilisateur?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Non
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            Oui
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getCurrentInstance, ref, onMounted, watch,
} from '@vue/composition-api'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import { useRouter } from '@core/utils'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";

export default {
  name: 'Recovery',
  components: {ButtonsGroup, CustomToolbar, LoaderDialog },

  setup() {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const date = ref(null)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const menu = ref(false)
    const isDialogVisible = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const { router } = useRouter()

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const save = d => {
      menuref.value.save(d)
    }

    const getRecoveries = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('recovery/getRecoveriesList', { page, field: store.state.recovery.field }).then(() => {
        currentPage.value = store.getters['recovery/current_page']
        lastPage.value = store.getters['recovery/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const refreshRecoveries = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('recovery/refreshRecoveriesList', { page, field: store.state.recovery.field }).then(() => {
        currentPage.value = store.getters['recovery/current_page']
        lastPage.value = store.getters['recovery/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const filterItems = () => {
      setTimeout(() => {
        getRecoveries()
      }, 1500)
    }

    const getRecoveriesPerPage = per_page => {
      isDialogVisible.value = true
      store.state.recovery.field.paginate = per_page
      store.dispatch('recovery/getRecoveriesList', { field: store.state.recovery.field }).then(() => {
        currentPage.value = store.getters['recovery/current_page']
        lastPage.value = store.getters['recovery/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const formattingDate = date => formatDate(date)

    const closeDelete = () => {
      this.dialogDelete = false
    }

    const deleteItemConfirm = () => {
      this.closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showrecovery', params: { id: item.id } })
    }

    watch(() => store.state.user.lang, lang => {
      refreshRecoveries(store.getters['recovery/current_page'])
    })

    onMounted(() => {
      getRecoveries(store.getters['recovery/current_page'])
    })

    return {
      filterItems,
      openItem,
      getRecoveriesPerPage,
      deleteItemConfirm,
      closeDelete,
      save,
      getRecoveries,
      formattingDate,
      currentPage,
      message,
      lastPage,
      isAlertVisible,
      isDialogVisible,
      dialogDelete,
      error,
      date,
      menu,
      picker,
      menuref,
      formatAmount,
      headers: [
        { text: 'MOTIF', value: 'rec_type.label' },
        { text: 'MEMBRE', value: 'user' },
        { text: 'MONTANT', value: 'amount' },
        { text: 'FRAIS', value: 'fee_amount' },
        { text: 'RETRAIT DES FONDS', value: 'to_withdraw' },
        { text: 'STATUT', value: 'status' },
        { text: 'VALIDATEUR', value: 'admin' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>

<style scoped>

</style>
